import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const ProductionSummaryCardHeader: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Stack direction="row" spacing={2} sx={{justifyContent: 'space-between'}}>
      <Typography variant="h3">{t('planning.production')}</Typography>
      <Stack direction="row">
        {/* TODO Autofill button */}
      </Stack>
    </Stack>
  )
}
