import {MaterialType} from '@hconnect/common/types'
import React, {Suspense} from 'react'
import {Navigate, Routes, Route, Outlet} from 'react-router-dom'

import {subPageDynamicImportMap} from '../dynamicImports'
import {FeatureGuardedRoute} from '../routing/FeatureGuardedRoute'
import {getRelPath, getUrl} from '../routing/helpers'
import {PermissionGuardedRoute} from '../routing/PermissionGuardedRoute'
import {PLANT_ROUTES, RouteName, ROUTES} from '../routing/router'
import {PlannerPageSkeleton} from '../shared/components/skeletons/PlannerPageSkeleton'
import {PlannerFeature} from '../shared/enums'
import {useUrlParam} from '../shared/hooks/useUrlParam'
import {mainPagesToPermissionsMap} from '../shared/permissions'

import {PageLastPlanDetails} from './page-optimizer/PageLastPlanDetails'
import {PageOptimizedPlanDetails} from './page-optimizer/PageOptimizedPlanDetails'
import {PageOptimizer} from './page-optimizer/PageOptimizer'
import {PageOptimizerSummary} from './page-optimizer/PageOptimizerSummary'
import {PageDailyProductionPlanning} from './page-planning/dailyPlanning/PageDailyProductionPlanning'
import {PageMonthlyProductionPlanning} from './page-planning/monthlyPlanning/PageMonthlyProductionPlanning'
import {PageMaterialOrders} from './page-stock/materialOrders/PageMaterialOrders'
import {PageMaterials} from './page-stock/PageMaterials'
import {PageSilos} from './page-stock/PageSilos'

const PageStock = React.lazy(subPageDynamicImportMap[RouteName.STOCK])
const PageElectricity = React.lazy(subPageDynamicImportMap[RouteName.ELECTRICITY_FORECAST])
const PageDemand = React.lazy(subPageDynamicImportMap[RouteName.SALES_FORECAST])
const PageControlOperation = React.lazy(subPageDynamicImportMap[RouteName.OPERATOR_VIEW])
const PagePlanning = React.lazy(subPageDynamicImportMap[RouteName.PRODUCTION_PLANNING])

export const GuardedRoutes: React.FC = () => {
  const plantCode = useUrlParam('plantCode')
  return (
    <Suspense fallback={<PlannerPageSkeleton />}>
      <Routes>
        <Route
          path={getRelPath(PLANT_ROUTES.STOCK.path)}
          element={<FeatureGuardedRoute feature={PlannerFeature.Stock} />}
        >
          <Route
            path=""
            element={
              <PermissionGuardedRoute
                requiredPermissions={mainPagesToPermissionsMap.stock.permissionTypes}
                operator={mainPagesToPermissionsMap.stock.operator}
              />
            }
          >
            <Route path="" element={<PageStock />}>
              <Route
                index
                element={
                  <Navigate
                    to={`${getUrl(PLANT_ROUTES.STOCK.SILOS.path, {plantCode})}?materialType=${
                      MaterialType.Cement
                    }`}
                    replace
                  />
                }
              />
              <Route path={getRelPath(PLANT_ROUTES.STOCK.SILOS.path)} element={<PageSilos />} />
              <Route
                path={getRelPath(PLANT_ROUTES.STOCK.MATERIALS.path)}
                element={<PageMaterials />}
              />
            </Route>
            <Route path={getRelPath(PLANT_ROUTES.STOCK.MATERIALS.path)}>
              <Route
                path={getRelPath(PLANT_ROUTES.STOCK.MATERIALS.ORDERS.path)}
                element={<FeatureGuardedRoute feature={PlannerFeature.RawMaterialsPlanning} />}
              >
                <Route
                  path=""
                  element={
                    <PermissionGuardedRoute requiredPermissions={['VIEW_MATERIAL_ORDERS']} />
                  }
                >
                  <Route path="" element={<PageMaterialOrders />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          path={getRelPath(PLANT_ROUTES.ELECTRICITY_FORECAST.path)}
          element={<FeatureGuardedRoute feature={PlannerFeature.PriceForecast} />}
        >
          <Route
            path=""
            element={
              <PermissionGuardedRoute
                requiredPermissions={mainPagesToPermissionsMap.electricityForecast.permissionTypes}
                operator={mainPagesToPermissionsMap.electricityForecast.operator}
              />
            }
          >
            <Route path="" element={<PageElectricity />} />
          </Route>
        </Route>
        <Route
          path={getRelPath(PLANT_ROUTES.SALES_FORECAST.path)}
          element={<FeatureGuardedRoute feature={PlannerFeature.DemandForecast} />}
        >
          <Route
            path=""
            element={
              <PermissionGuardedRoute
                requiredPermissions={mainPagesToPermissionsMap.salesForecast.permissionTypes}
                operator={mainPagesToPermissionsMap.salesForecast.operator}
              />
            }
          >
            <Route path="" element={<PageDemand />} />
          </Route>
        </Route>
        <Route
          path={getRelPath(PLANT_ROUTES.PRODUCTION_PLANNING.path)}
          element={<FeatureGuardedRoute feature={PlannerFeature.Planning} />}
        >
          <Route
            path=""
            element={
              <PermissionGuardedRoute
                requiredPermissions={mainPagesToPermissionsMap.productionPlanning.permissionTypes}
                operator={mainPagesToPermissionsMap.productionPlanning.operator}
              />
            }
          >
            <Route path="" element={<PagePlanning />}>
              <Route
                index
                element={
                  <Navigate to={getRelPath(PLANT_ROUTES.PRODUCTION_PLANNING.DAILY.path)} replace />
                }
              />
              <Route
                path={getRelPath(PLANT_ROUTES.PRODUCTION_PLANNING.DAILY.path)}
                element={<PageDailyProductionPlanning />}
              />
              <Route
                path={getRelPath(PLANT_ROUTES.PRODUCTION_PLANNING.MONTHLY.path)}
                element={<PageMonthlyProductionPlanning />}
              />
            </Route>
            <Route
              path={getRelPath(PLANT_ROUTES.PRODUCTION_PLANNING.OPTIMIZER.path)}
              element={<PageOptimizer />}
            >
              <Route
                index
                element={
                  <Navigate
                    to={getRelPath(PLANT_ROUTES.PRODUCTION_PLANNING.OPTIMIZER.SUMMARY.path)}
                    replace
                  />
                }
              />
              <Route
                path={getRelPath(PLANT_ROUTES.PRODUCTION_PLANNING.OPTIMIZER.SUMMARY.path)}
                element={<PageOptimizerSummary />}
              />
              <Route
                path={getRelPath(PLANT_ROUTES.PRODUCTION_PLANNING.OPTIMIZER.DETAILS.path)}
                element={<Outlet />}
              >
                <Route
                  index
                  element={
                    <Navigate
                      to={getRelPath(
                        PLANT_ROUTES.PRODUCTION_PLANNING.OPTIMIZER.DETAILS.OPTIMIZED_PLAN.path
                      )}
                      replace
                    />
                  }
                />
                <Route
                  path={getRelPath(
                    PLANT_ROUTES.PRODUCTION_PLANNING.OPTIMIZER.DETAILS.OPTIMIZED_PLAN.path
                  )}
                  element={<PageOptimizedPlanDetails />}
                />
                <Route
                  path={getRelPath(
                    PLANT_ROUTES.PRODUCTION_PLANNING.OPTIMIZER.DETAILS.LAST_PLAN.path
                  )}
                  element={<PageLastPlanDetails />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route
          path={getRelPath(PLANT_ROUTES.OPERATOR_VIEW.path)}
          element={<FeatureGuardedRoute feature={PlannerFeature.ControlOperatorScreen} />}
        >
          <Route
            path=""
            element={
              <PermissionGuardedRoute
                requiredPermissions={mainPagesToPermissionsMap.operatorView.permissionTypes}
                operator={mainPagesToPermissionsMap.operatorView.operator}
              />
            }
          >
            <Route path="" element={<PageControlOperation />} />
          </Route>
        </Route>
        <Route
          path="*"
          element={<Navigate to={getUrl(ROUTES.BASE.NOT_FOUND.path, {})} replace />}
        />
      </Routes>
    </Suspense>
  )
}
