import {Status} from '@hconnect/common/types'
import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {MenuItem, TextField} from '@mui/material'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {dataTestId} from '@hconnect/uikit'
import {useCurrentTime} from '../../../../../../shared/hooks/useCurrentTime'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {OperationModeResponse, ScheduleItem} from '../../../../../../shared/interfaces/api'
interface ScheduleItemOperationModeSelectProps {
  scheduleItem: ScheduleItem
  operationModes: OperationModeResponse[]
  handleOperationModeChange: (operationModeId: number, shouldSplit: boolean) => void
}
export const ScheduleItemOperationModeSelect: React.FC<ScheduleItemOperationModeSelectProps> = ({
  scheduleItem,
  operationModes: allOperationModes,
  handleOperationModeChange
}) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const now = useCurrentTime({timezoneId})
  const {assetOperationModeId} = scheduleItem

  const isOperationModeChangeDisabled = moment
    .utc(scheduleItem.end)
    .isBefore(roundTo15MinIntervalStart(now))
  const shouldDisplaySplitWarning = moment
    .utc(scheduleItem.start)
    .isBefore(roundTo15MinIntervalStart(now))

  const onOperationModeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleOperationModeChange(Number(e.target.value), shouldDisplaySplitWarning)

  // we need to filter out the Deleted operation modes in editable mode
  const operationModes = isOperationModeChangeDisabled
    ? allOperationModes
    : allOperationModes.filter((operationMode) => operationMode.status !== Status.Deleted)

  return (
    <TextField
      select
      label={t('planning.operationMode')}
      value={assetOperationModeId}
      fullWidth
      onChange={onOperationModeChange}
      variant="outlined"
      disabled={isOperationModeChangeDisabled}
      {...dataTestId('operation_mode_select')}
    >
      {operationModes.map((operationMode) => {
        return (
          <MenuItem
            key={`${operationMode.id}`}
            value={operationMode.id}
            {...dataTestId('operation_mode_item')}
          >
            {operationMode.name}
          </MenuItem>
        )
      })}
    </TextField>
  )
}
