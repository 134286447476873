import {dataTestId} from '@hconnect/uikit'
import {Card} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {useSelectedTimeframe} from '../../../shared/components/providers/SelectedTimeframeProvider'

import {ProductionSummaryCardHeader} from './ProductionSummaryCardHeader'

export const ProductionSummaryCard: React.FC = () => {
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('BUG: selectedTimeframe is not defined')
  }

  return (
    <Card
      headerContent={<ProductionSummaryCardHeader />}
      {...dataTestId('production_summary_card')}
    >
      <>{/* TODO material production summary table */}</>
    </Card>
  )
}
