import moment, {Moment} from 'moment-timezone'

import {LatestDemand} from '../interfaces/api'
import {DatetimeValue} from '../interfaces/common'

import {groupBy} from './time'

/**
 * Helper function to format demand forecast values into a single day's forecast values to simplify calculations.
 */
export const groupDemandByDay = (demands: DatetimeValue[] | undefined, timezoneId: string) =>
  groupBy(demands ?? [], (datetime) =>
    moment.utc(datetime).tz(timezoneId).add(1, 'day').startOf('day').toISOString()
  )

/**
 * Helper function to get hourly demand dictionary for a specific material
 */

export const getHourlyDemandDictionary = ({
  start,
  end,
  materialId,
  demand
}: {
  start: Moment
  end: Moment
  demand: LatestDemand
  materialId: number
}): Record<string, number> => {
  const demandForMaterial = demand[String(materialId)]?.forecast ?? []

  const demandForRange = demandForMaterial
    .map(({datetime, value}) => ({datetime: moment.utc(datetime), value}))
    .filter(({datetime}) => {
      return start.isBefore(datetime) && end.isSameOrAfter(datetime)
    })

  const hourlyDemandDictionary = demandForRange.reduce<Record<string, number>>(
    (acc, {datetime, value}) => {
      acc[datetime.toISOString()] = value
      return acc
    },
    {}
  )
  return hourlyDemandDictionary
}
