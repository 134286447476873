import {dataTestId} from '@hconnect/uikit'
import {Box, Stack} from '@mui/material'
import React from 'react'

import {useSelectedTimeframe} from '../../shared/components/providers/SelectedTimeframeProvider'
import {useHistoryAssetsQuery, useMaterialsRecipes} from '../../shared/hooks/api'

import {AssetCapacityCard} from './monthlyPlanning/AssetCapacityCard'
import {MonthlyProductionPlanningActions} from './monthlyPlanning/MonthlyProductionPlanningActions'
import {ProductionSummaryCard} from './monthlyPlanning/ProductionSummaryCard'
import {PageProductionPlanningHeader} from './PageProductionPlanningHeader'

export const LayoutMonthlyPlanning: React.FC = () => {
  const {selectedTimeframe} = useSelectedTimeframe()
  if (!selectedTimeframe) {
    throw new Error('selectedTimeframe is not defined')
  }

  const [startOfPlan, endOfPlan] = selectedTimeframe

  // prefetching required data
  useHistoryAssetsQuery({
    timeFrame: [startOfPlan, endOfPlan],
    sorted: true
  })
  useMaterialsRecipes()

  return (
    <Stack spacing={2} {...dataTestId('monthly_planning_layout')}>
      <PageProductionPlanningHeader actions={<MonthlyProductionPlanningActions />} />
      <Stack spacing={2} direction="row">
        <Box sx={{flex: 1}}>
          <ProductionSummaryCard />
        </Box>
        <Box sx={{flex: 1}}>
          <AssetCapacityCard />
        </Box>
      </Stack>
    </Stack>
  )
}
