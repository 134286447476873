import {MaterialWithRecipes} from '@hconnect/common/types'
import moment from 'moment-timezone'

import {OptimizerCalculationStatus} from '../../../shared/enums'
import type {
  Latest,
  Schedule,
  Comment,
  ElectricityResponse,
  ScheduleCostAvoidance,
  CostAvoidanceMonthly,
  CostAvoidanceForRange,
  MaterialOrder,
  ScheduleAgreementsByVendor,
  StockDevelopment,
  PlantConfigData,
  LatestPeakLoadWindow,
  ElectricityPrice,
  GroupedUnplannedDelivery,
  AssetCapacityResponse
} from '../../../shared/interfaces/api'
import {
  getAssetsFromHistoryByDate,
  getBaseLoadPowerConsumptionFromHistoryAssets,
  getHistoryAssetsById,
  mapScheduleItemIdToAssetData,
  sortAssets
} from '../../../shared/selectors/assets'
import {
  getStoragesFromHistory,
  isHistoryStorageWithMaterialGuard,
  isStorageWithMaterialGuard
} from '../../../shared/selectors/storages'
import {MockState} from '../../mockState'

import assetsCapacityByMonth from './assetsCapacityByMonth.json'
import comments from './comments.json'
import costAvoidanceDaily from './costAvoidanceDaily.json'
import costAvoidanceForRange from './costAvoidanceForRange.json'
import costAvoidanceMonthly from './costAvoidanceMonthly.json'
import currentMaterialStorages from './currentStorages'
import electricity from './electricity.json'
import electricityPrice from './electricityPrice.json'
import historyAssets from './historyAssets'
import materialStorageHistory from './historyStorages'
import latestRaw from './latest.json'
import materialOrders from './materialOrders.json'
import {materialsHistory} from './materialsHistory'
import materialsWithRecipes from './materialsWithRecipes.json'
import materialUnplannedDeliveries from './materialUnplannedDeliveries.json'
import optimized_schedule from './optimized_schedule.json'
import peakLoadWindows from './peakLoadWindows.json'
import plantCofig from './plantConfig.json'
import schedule from './schedule.json'
import scheduleAgreements from './scheduleAgreementsByVendor.json'
import schedulesCostAvoidance from './schedulesCostAvoidance.json'
import stockDevelopment from './stockDevelopment.json'

const startOfPlan = '2019-08-15T22:00:00.000Z'
const endOfPlan = '2019-08-22T22:00:00.000Z'

export const mockState: MockState = {
  burglengenfeld: {
    start: startOfPlan,
    end: endOfPlan,
    latest: latestRaw as Latest,
    peakLoadWindows: peakLoadWindows as LatestPeakLoadWindow[],
    electricityPrice: electricityPrice as ElectricityPrice,
    plantConfig: plantCofig as PlantConfigData,
    optimizer_calc_status: {status: OptimizerCalculationStatus.Finished},
    schedule: schedule as Schedule,
    schedulesCostAvoidance: schedulesCostAvoidance as Record<string, ScheduleCostAvoidance>,
    costAvoidanceMontly: costAvoidanceMonthly as CostAvoidanceMonthly[],
    costAvoidanceDaily: costAvoidanceDaily as ScheduleCostAvoidance[],
    costAvoidanceForRange: costAvoidanceForRange as CostAvoidanceForRange,
    optimized_schedule: optimized_schedule as Schedule,
    electricity: electricity as ElectricityResponse,
    comments: comments as Comment[],
    materialsWithRecipes: materialsWithRecipes as MaterialWithRecipes[],
    recipesById: materialsWithRecipes
      .flatMap((material) => material.recipes)
      .reduce((recipesById, recipe) => ({...recipesById, [recipe.id]: recipe}), {}),
    materialsById: materialsWithRecipes.reduce(
      (materialsById, material) => ({...materialsById, [material.id]: material}),
      {}
    ),
    materialsHistory,
    historyAssets,
    assets: getAssetsFromHistoryByDate(historyAssets, moment.utc(endOfPlan)),
    sortedAssets: sortAssets(getAssetsFromHistoryByDate(historyAssets, moment.utc(endOfPlan))),
    assetsById: getHistoryAssetsById(historyAssets, moment.utc(endOfPlan)),
    baseLoad: getBaseLoadPowerConsumptionFromHistoryAssets(historyAssets, moment.utc(endOfPlan)),
    currentMaterialStorages: currentMaterialStorages,
    currentMaterialStoragesWithMaterial: currentMaterialStorages.filter(isStorageWithMaterialGuard),
    materialStorageHistory,
    historyMaterialStorage: getStoragesFromHistory(materialStorageHistory, moment.utc(endOfPlan)),
    historyStoragesWithMaterial: getStoragesFromHistory(
      materialStorageHistory,
      moment.utc(endOfPlan)
    ).filter(isHistoryStorageWithMaterialGuard),
    materialOrders: materialOrders as MaterialOrder[],
    scheduleAgreements: scheduleAgreements as ScheduleAgreementsByVendor[],
    materialUnplannedDeliveries: materialUnplannedDeliveries as GroupedUnplannedDelivery[],
    stockDevelopment: stockDevelopment as StockDevelopment,
    optimizedStockDevelopment: Object.fromEntries(
      Object.entries(stockDevelopment).map(([materialId, stockDevelopmentEntry]) => [
        materialId,
        {
          actual: stockDevelopmentEntry.actual,
          forecast: stockDevelopmentEntry.forecast.map(({datetime, value}) => ({
            datetime,
            // increasing value by 5% for testing purposes
            value: value * 1.05
          }))
        }
      ])
    ) as StockDevelopment,
    assetHistoryDataByScheduleItemId: mapScheduleItemIdToAssetData(
      schedule.schedules as Schedule['schedules'],
      historyAssets
    ),
    assetsCapacityByMonth: assetsCapacityByMonth as Record<string, AssetCapacityResponse>
  }
}
