import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlannerSwitch} from '../../../../../../shared/components/formComponents/PlannerSwitch'
import {dataTestId} from '@hconnect/uikit'
import {useCurrentTime} from '../../../../../../shared/hooks/useCurrentTime'
import {usePlantConfig} from '../../../../../../shared/hooks/usePlantConfigData'
import {ScheduleItem} from '../../../../../../shared/interfaces/api'

interface ScheduleItemTransitionTimeSwitchProps {
  scheduleItem: ScheduleItem
  handleTransitionTimeChange: (isTransitionTime: boolean, shouldSplit: boolean) => void
}
export const ScheduleItemTransitionTimeSwitch: React.FC<ScheduleItemTransitionTimeSwitchProps> = ({
  scheduleItem,
  handleTransitionTimeChange
}) => {
  const {t} = useTranslation()
  const {timezone_id: timezoneId} = usePlantConfig()
  const now = useCurrentTime({timezoneId})
  const isTransitionTimeChangeDisabled = moment
    .utc(scheduleItem.end)
    .isBefore(roundTo15MinIntervalStart(now))

  const shouldDisplaySplitWarning = moment
    .utc(scheduleItem.start)
    .isBefore(roundTo15MinIntervalStart(now))

  const onTransitionTimeChange = (isChecked: boolean) =>
    handleTransitionTimeChange(isChecked, shouldDisplaySplitWarning)

  return (
    <PlannerSwitch
      label={t('planning.transitionTime')}
      value={scheduleItem.isTransitionTime}
      onChange={onTransitionTimeChange}
      disabled={isTransitionTimeChangeDisabled}
      {...dataTestId('transition_time_toggle')}
    />
  )
}
